var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"show-list"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"btn",on:{"click":_vm.toAdd}},[_vm._v("添加展示")]),_c('Table',{attrs:{"pageInfo":_vm.listQuery,"loading":_vm.listLoading,"header":_vm.tableHeader,"content":_vm.tableData,"height":98,"keyField":"id"},scopedSlots:_vm._u([{key:"coverImage",fn:function(ref){
var item = ref.item;
return [(item.coverImage)?_c('div',{staticClass:"pic",on:{"click":function($event){return _vm.viewImg(_vm.$constants.common.imgPrefix + item.coverImage)}}},[_c('img',{attrs:{"src":("" + (_vm.$constants.common.imgPrefix + item.coverImage))}})]):_vm._e()]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.filterDictName(_vm.showTypeDicts, item.type))+" ")])]}},{key:"display",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.display ? '是' : '否')+" ")])]}},{key:"opt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"opt"},[(!_vm.lastOne)?_c('span',{staticClass:"delete",on:{"click":function($event){_vm.isOpenModal = true
              _vm.showId = item.id}}},[_vm._v(" 删除 ")]):_c('span',{staticStyle:{"color":"#B3B3B4"}},[_vm._v(" 删除 ")]),(!_vm.lastOne)?_c('span',{staticClass:"cancel",on:{"click":function($event){return _vm.updateState(item.id, !item.display)}}},[_vm._v(" "+_vm._s(item.display ? '取消展示' : '展示')+" ")]):_c('span',{staticStyle:{"color":"#B3B3B4"}},[_vm._v(" 取消展示 ")]),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.toEdit(item.id)}}},[_vm._v("编辑")])])]}}])}),_c('div',{staticClass:"pagination"},[_c('pagination',{attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit,"layout":_vm.layout,"pageSizes":[7, 14, 21]},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getShowList}})],1)],1),(_vm.imgUrl !== '')?_c('ImgView',{attrs:{"url":_vm.imgUrl},on:{"close":_vm.closeImg}}):_vm._e(),_c('TipModal',{attrs:{"isShow":_vm.isOpenModal,"title":"操作警告","tip":"是否删除该条展示？"},on:{"optSuccess":_vm.afterOpt,"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }