



























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    TipModal
  }
})
export default class ShowList extends Vue {
  // 字典数据
  // 展示类型
  showTypeDicts: any = []
  listLoading = false
  tableHeader = [
    {
      key: 'coverImage',
      name: '展示图',
      width: '20%',
      isSpecial: true
    },
    {
      key: 'title',
      name: '标题',
      width: ''
    },
    {
      key: 'type',
      name: '分类',
      width: '14%',
      isSpecial: true
    },
    {
      key: 'updatedTime',
      name: '更新时间',
      width: '16%'
    },
    {
      key: 'display',
      name: '是否展示',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '14%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 7
  }
  imgUrl = ''
  isOpenModal = false
  showId = 0
  lastOne = false

  async beforeCreate() {
    // 获取相关字典
    // 展示类型
    this.showTypeDicts = await getDicts('DISPLAY_TYPE')
  }

  created() {
    this.getShowList()
  }

  // 获取展示列表
  getShowList() {
    this.listLoading = true
    this.$api.property
      .getShowList({
        current: this.listQuery.page,
        size: this.listQuery.limit
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        if (data.total === 1) {
          this.lastOne = true
          if (!data.data[0].display) {
            this.updateState(data.data[0].id, true)
          }
        } else {
          this.lastOne = false
        }
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 更新展示状态
  updateState(id: number, display: boolean) {
    this.$api.property
      .updateShowState({
        id,
        display
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.getShowList()
          this.$message.success(data.msg)
        } else {
          this.$message.error(data.msg)
        }
      })
  }

  // 删除展示
  deleteShow() {
    this.$api.property.deleteShow(this.showId).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.getShowList()
        this.$message.success('删除成功')
      } else {
        this.$message.error(data.msg)
      }
    })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  toAdd() {
    this.$router.push('/property/show-edit')
  }

  toEdit(id: number) {
    this.$router.push({
      path: '/property/show-edit',
      query: {
        id: id + ''
      }
    })
  }

  // 关闭对话框
  closeDialog() {
    this.isOpenModal = false
  }
  // 操作对话框后统一回调
  afterOpt() {
    this.deleteShow()
    this.closeDialog()
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }
}
